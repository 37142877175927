<template>
  <b-row>
    <b-col v-if="isDate">
      <b-input-group size="sm">
        <b-input-group-prepend is-text
          ><input type="checkbox" v-model="checkedDate"
        /></b-input-group-prepend>
        <datepicker
          id="from-datepicker-util"
          name="start_date"
          v-model="start_date"
          :value="start_date.date"
          v-on:selected="changeStart"
          :language="ko"
          format="yyyy/MM/dd"
          placeholder="시작일"
          input-class="form-control custom-select-sm radius0
        datew"
        />
        <b-input-group-addon is-text style="margin-left: -1px"><b>~</b></b-input-group-addon>
        <datepicker
          id="to-datepicker-util"
          name="end_date"
          v-model="end_date"
          :value="end_date.date"
          v-on:selected="changeEnd"
          :language="ko"
          format="yyyy/MM/dd"
          placeholder="종료일"
          input-class="form-control custom-select-sm datew"
        />
        <template #append v-if="isDateTitle">
          <b-dropdown :text="dateSelected.text" size="sm">
            <b-dropdown-item
              v-for="item in dateOptions"
              :key="item.value"
              @click="dateSelected = item"
              >{{ item.text }}</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-input-group>
    </b-col>
    <b-col v-if="isStore" cols="2">
      <b-form-checkbox-group v-model="storeCd">
        <b-form-checkbox value="FM">F</b-form-checkbox>
        <b-form-checkbox value="UP">P</b-form-checkbox>
        <b-form-checkbox value="EDY">E</b-form-checkbox>
      </b-form-checkbox-group>
    </b-col>
    <b-col :cols="isDate ? (isStore ? 4 : 6) : ''">
      <b-input-group size="sm">
        <!-- <b-input-group-prepend size="sm">
          <b-form-select
            slot="prepend"
            type="search"
            v-model="selected"
            :options="options"
            size="sm"
          ></b-form-select>
        </b-input-group-prepend> -->
        <template #prepend>
          <b-dropdown :text="selected.text" size="sm">
            <b-dropdown-item v-for="item in options" :key="item.value" @click="selected = item">{{
              item.text
            }}</b-dropdown-item>
          </b-dropdown>
        </template>
        <b-form-input v-model="filter" placeholder="Search" @keyup.enter="search"></b-form-input>
        <b-input-group-append is-text size="sm">
          <b-icon icon="search" @click="search"></b-icon>
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { ko } from 'vuejs-datepicker/dist/locale'
var now = new Date()
var preDate = new Date(now.setMonth(now.getMonth() - 1))

export default {
  name: 'search',
  components: { Datepicker },
  props: {
    isDate: {
      type: Boolean,
      default: false
    },
    isStore: {
      type: Boolean,
      default: false
    },
    isDateTitle: { type: Boolean, default: true }
  },
  data() {
    return {
      ko: ko,
      filter: null,
      selected: { value: '', text: '선택' },
      options: [
        { value: '', text: '선택' },
        { value: 'user_sn', text: '회원번호' },
        { value: 'email', text: '이메일' },
        { value: 'name', text: '닉네임' },
        { value: 'hpno', text: '핸드폰번호' }
      ],
      dateSelected: { value: 'reg_dt', text: '가입일' },
      dateOptions: [
        { value: 'reg_dt', text: '가입일' },
        { value: 'upd_dt', text: '수정일' }
      ],
      start_date: this.$moment(preDate).format('YYYY/MM/DD'),
      end_date: this.$moment(new Date()).format('YYYY/MM/DD'),
      checkedDate: false,
      storeCd: ['FM', 'UP', 'EDY']
    }
  },
  watch: {
    storeCd: function() {
      this.search()
    },
    dateSelected: function() {
      if (this.checkedDate) this.search()
    },
    checkedDate: function() {
      this.search()
    }
  },
  methods: {
    search() {
      console.log(this.start_date, this.end_date, this.checkedDate)
      let start, end, searchDate
      if (this.checkedDate) {
        if (typeof this.start_date === 'string') start = this.start_date.replace(/\//g, '')
        else start = this.$moment(this.start_date).format('YYYYMMDD')
        if (typeof this.end_date === 'string') end = this.end_date.replace(/\//g, '')
        else end = this.$moment(this.end_date).format('YYYYMMDD')
        searchDate = this.dateSelected.value
      }
      this.$emit('search', {
        key: this.selected.value,
        value: this.filter,
        start,
        end,
        store: this.storeCd,
        searchDate
      })
    },
    changeStart(val) {
      this.start_date = this.$moment(val).format('YYYY/MM/DD')
      this.search()
    },
    changeEnd(val) {
      this.end_date = this.$moment(val).format('YYYY/MM/DD')
      this.search()
    }
  }
}
</script>
<style>
.radius0 {
  border-radius: 0;
}
.datew {
  width: 120px;
}
</style>
